var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"persistent":"","fullscreen":"","scrollable":"","no-click-animation":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.voltar.apply(null, arguments)}},model:{value:(_vm.syncMostra),callback:function ($$v) {_vm.syncMostra=$$v},expression:"syncMostra"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"tile":"","dark":""}},[_c('v-card-title',{staticClass:"mr-500"},[_c('h3',[_c('v-icon',{staticClass:"mr-2",on:{"click":_vm.voltar}},[_vm._v("mdi-arrow-left")]),_vm._v("Devolução ")],1)]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mx-auto",staticStyle:{"width":"auto"},attrs:{"disabled":_vm.iniciandoDevolucao},on:{"submit":function($event){$event.preventDefault();return (function () { return (_vm.etapaDaDevolucao === 'escolhendo-itens' ? _vm.devolver() : _vm.buscarVenda()); }).apply(null, arguments)}}},[(_vm.etapaDaDevolucao === 'filtros')?_c('v-container',[_c('v-row',[_c('CampoDeCodigoDaVenda',{ref:"campoDeNumeroDaVenda",attrs:{"filled":"","color":"success","label":"Código da Venda","type":"text","hide-details":"","disabled":!!_vm.parametros.numeroDaNota ||
									!!_vm.parametros.serieFiscal ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.buscando},on:{"click:clear":function($event){_vm.parametros.identificador = ''}},model:{value:(_vm.parametros.identificador),callback:function ($$v) {_vm.$set(_vm.parametros, "identificador", $$v)},expression:"parametros.identificador"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider"},[_c('strong',{staticClass:"px-3",staticStyle:{"background-color":"#1E1E1E"}},[_vm._v("OU")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"campoDeNumeroDaNota",attrs:{"color":"success","filled":"","autocomplete":"off","label":"Nº Nota Fiscal","min":"0","type":"number","inputmode":"numeric","disabled":!!_vm.parametros.identificador ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.buscando},on:{"click:clear":function($event){_vm.parametros.numeroDaNota = ''}},model:{value:(_vm.parametros.numeroDaNota),callback:function ($$v) {_vm.$set(_vm.parametros, "numeroDaNota", $$v)},expression:"parametros.numeroDaNota"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"color":"success","filled":"","autocomplete":"off","label":"Série","type":"text","disabled":!!_vm.parametros.identificador ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.buscando},on:{"click:clear":function($event){_vm.parametros.serieFiscal = ''}},model:{value:(_vm.parametros.serieFiscal),callback:function ($$v) {_vm.$set(_vm.parametros, "serieFiscal", $$v)},expression:"parametros.serieFiscal"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider"},[_c('strong',{staticClass:"px-2",staticStyle:{"background-color":"#1E1E1E"}},[_vm._v("OU")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('SeletorDeCliente',{attrs:{"label":"Clientes","return-object":"","disabled":!!_vm.parametros.numeroDaNota ||
									!!_vm.parametros.serieFiscal ||
									!!_vm.parametros.identificador ||
									_vm.buscando,"filled":""},model:{value:(_vm.parametros.cliente),callback:function ($$v) {_vm.$set(_vm.parametros, "cliente", $$v)},expression:"parametros.cliente"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BuscaDeProdutoDropdown',{ref:"campoDeProduto",attrs:{"label":"Produto","filtros":_vm.tiposDeProdutos,"return-object":"","requerFilho":"","filled":"","disabled":!!_vm.parametros.numeroDaNota ||
									!!_vm.parametros.serieFiscal ||
									!!_vm.parametros.identificador ||
									_vm.buscando,"clearable":""},model:{value:(_vm.parametros.produto),callback:function ($$v) {_vm.$set(_vm.parametros, "produto", $$v)},expression:"parametros.produto"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"data-cy":"buscar","color":"success","block":"","disabled":!_vm.parametros.identificador &&
									!(_vm.parametros.numeroDaNota && _vm.parametros.serieFiscal) &&
									!_vm.isClienteInformado && 
									!_vm.isProdutoInformado,"loading":_vm.buscando,"type":"submit"}},[_vm._v(" Buscar ")])],1)],1)],1):_vm._e(),(_vm.etapaDaDevolucao === 'pesquisas')?_c('v-container',{attrs:{"fluid":""}},[(!_vm.buscando)?_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headersVendas,"items":_vm.vendasFormatadas,"hideCreate":"","loading":_vm.buscandoVendas,"sort-by":"dataHora","sort-desc":[true],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
									var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaVenda',{attrs:{"venda":item,"exibirDetalheCompleto":false},on:{"devolverVenda":_vm.selecionarVendaParaDevolucao},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes da venda ")])]}}],null,true)}),_c('Confirmacao',{attrs:{"width":"300","titulo":"Devolver esta venda?","subtitulo":("<div>" + (item.identificador) + "</div>")},on:{"confirmar":function () { return _vm.selecionarVendaParaDevolucao(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s('mdi-keyboard-return')+" ")])]}}],null,true)},[_vm._v(" Devolver ")])]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.vendasFormatadas.length,"loading":_vm.buscandoVendas},on:{"navegar":_vm.atualizarPagina}})]},proxy:true}],null,true)})],1):_c('v-card',[_c('AppLoading')],1)],1):_vm._e(),(_vm.etapaDaDevolucao === 'escolhendo-itens')?_c('v-container',[_c('div',[_c('v-text-field',{attrs:{"label":"Digite o nome do produto ou EAN...","single-line":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itensFormatados,"show-select":"","items-per-page":6,"page":_vm.pagina,"hide-default-footer":"","search":_vm.busca},on:{"update:page":function($event){_vm.pagina=$event}},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
									var item = ref.item;
return [(_vm.devolucaoParcial)?_c('div',[_c('v-text-field',{ref:"checkboxDeSelecionarTodos",staticClass:"quantidade-do-item",staticStyle:{"width":"115px"},attrs:{"disabled":_vm.estaSelecionado(item.id),"type":"number","max":_vm.obterQuantidadeOriginalDoItem(item.id),"min":"1","rules":[
											_vm.maiorQueZero,
											function (quantidade) { return _vm.validarQuantidade(quantidade, item.id); } ],"suffix":("/ " + (_vm.obterQuantidadeOriginalDoItem(item.id)))},on:{"input":function () {
												if (!_vm.selecionados.includes(item))
													{ _vm.selecionados.push(item) }
											}},model:{value:(item.quantidade),callback:function ($$v) {_vm.$set(item, "quantidade", _vm._n($$v))},expression:"item.quantidade"}})],1):_vm._e(),(!_vm.devolucaoParcial)?_c('div',[_c('v-text-field',{staticClass:"quantidade-do-item",staticStyle:{"width":"115px"},attrs:{"disabled":"","type":"number","max":_vm.obterQuantidadeOriginalDoItem('item.id'),"min":"1"},model:{value:(item.quantidade),callback:function ($$v) {_vm.$set(item, "quantidade", _vm._n($$v))},expression:"item.quantidade"}})],1):_vm._e()]}},{key:"item.preco",fn:function(ref){
											var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarMoeda(_vm.obterTotalDoItemDevolucao(item, _vm.obterQuantidadeOriginalDoItem(item.id))))+" ")]}},{key:"header.data-table-select",fn:function(){return [(_vm.devolucaoParcial)?_c('div',[_c('v-simple-checkbox',{ref:"checkboxDeSelecionarTodos",staticClass:"checkbox",attrs:{"data-cy":"selecionar-todos","color":"success","tabindex":"0","value":_vm.selecionados.length === _vm.itensFormatados.length,"indeterminate":!!_vm.selecionados.length &&
												_vm.selecionados.length !== _vm.itensFormatados.length},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return _vm.trocarSelecionarTodos.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return (function () { return _vm.devolver(); }).apply(null, arguments)}],"click":function($event){$event.stopPropagation();return _vm.trocarSelecionarTodos.apply(null, arguments)}}})],1):_vm._e()]},proxy:true},{key:"item.data-table-select",fn:function(ref){
												var isSelected = ref.isSelected;
												var select = ref.select;
return [(_vm.devolucaoParcial)?_c('div',[_c('v-simple-checkbox',{staticClass:"checkbox",attrs:{"data-cy":"selecionar","color":"success","value":isSelected,"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return select(!isSelected); }).apply(null, arguments)},"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return (function () { return select(!isSelected); }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return (function () { return _vm.devolver(); }).apply(null, arguments)}]}})],1):_vm._e()]}}],null,true),model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}}),_c('div',{staticClass:"pb-5"},[_c('v-pagination',{attrs:{"circle":"","color":"success","length":Math.ceil(_vm.itensFormatados.length / 6)},model:{value:(_vm.pagina),callback:function ($$v) {_vm.pagina=$$v},expression:"pagina"}})],1),(_vm.devolucaoParcial)?_c('v-card-actions',{staticClass:"flex-star"},[_c('v-btn',{attrs:{"disabled":!_vm.totalDeItens,"data-cy":"devolver","color":"success","x-large":"","block":"","type":"submit"}},[_vm._v(" Devolver "+_vm._s(_vm.totalDeItens)+" Itens ")])],1):_vm._e(),(!_vm.devolucaoParcial)?_c('v-card-actions',{staticClass:"flex-star"},[_c('v-btn',{staticClass:"checkbox",attrs:{"data-cy":"devolver","color":"success","x-large":"","block":"","type":"submit"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return (function () { return _vm.devolver(); }).apply(null, arguments)}}},[_vm._v(" Devolver "+_vm._s(_vm.totalDeItens)+" Itens ")])],1):_vm._e()],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }