




















































































import AlterarPrecosDaVendaPorCliente from '@/usecases/venda/AlterarPrecosDaVendaPorCliente'
import DialogoDeEdicaoDeCliente from '@/components/cliente/DialogoDeEdicaoDeCliente.vue'
import CampoDeCpfOuCnpjOculto from '@/components/ui/CampoDeCpfOuCnpjOculto.vue'
import { Vue, Component, Ref, Watch, PropSync } from 'vue-property-decorator'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarCliente } from '@/shareds/cliente-shareds'
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindClienteUseCase } from '@/usecases'
import { Cliente } from '@/models'
import DialogoDeAniversarioDoCliente from './DialogoDeAniversarioDoCliente.vue'
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import AlterarPrecosDaNotaPorCliente from '@/usecases/venda/AlterarPrecosDaNotaPorCliente'
import axios from 'axios'

@Component({
	components: {
		DialogoDeEdicaoDeCliente,
		CampoDeCpfOuCnpjOculto,
		DialogoDeAniversarioDoCliente,
		SeletorDePessoa,
	},
})
export default class CampoDeClienteNaEmissaoDeNota extends Vue {
	@Ref() campoDeCliente!: SeletorDePessoa | CampoDeCpfOuCnpjOculto
	@Ref() trocadorDeUtilizarApenasCpf!: { $el: HTMLDivElement }
	@Ref() dialogoEdicaoDeCliente!: DialogoDeEdicaoDeCliente
	@Ref() dialogoDeAniversarioDoCliente!: DialogoDeAniversarioDoCliente
	@PropSync('value') cpfOuCnpjDoCliente!: string

	mostraTrocarUtilizarCpf: boolean | null = null
	findClienteUseCase = new FindClienteUseCase()
	utilizarApenasCpf = true
	obrigatorio = obrigatorio
	isVestcasa = true
	buscandoCliente = false

	async created() {
		this.clienteAtualizadoNaVenda
	}

	async mostrarDialogoDeAniversario(){
		this.dialogoDeAniversarioDoCliente.mostrar
	}

	mostrarDialogoDeCliente() {
		if (!this.venda) return
		const cliente = !this.venda.cliente
			? criarCliente()
			: JSON.parse(JSON.stringify(this.venda.cliente))
		cliente.cnpjOuCpf = !cliente.cnpjOuCpf ? this.venda.cpfDoCliente : cliente.cnpjOuCpf
		this.dialogoEdicaoDeCliente.mostrar(cliente, !cliente.id)
	}

	async clienteAtualizadoNaVenda(){
		if (!this.venda || !this.venda.cliente) return

		const params = {
			gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id),
		}
		const axiosConfig = {
			cancelToken: axios.CancelToken.source().token,
		}
		const clienteAtualizado = await this.findClienteUseCase.get(this.venda.cliente.id, params, axiosConfig)
		this.associarClienteNaVenda(clienteAtualizado)
	}

	associarClienteNaVenda(cliente: Cliente) {
		if (!cliente) return
		this.aplicarClienteNaVenda(cliente, true)
	}

	focus() {
		this.campoDeCliente.focus()
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual
	}

	get usuario() {
		return UserLoginStore.usuario
	}

	get utilizandoCampoDeCpf() {
		return this.componenteDeCliente === 'CampoDeCpfOuCnpjOculto'
	}

	get obrigatorioInformarCliente() {
		return this.venda?.tipoDeTransacao === 'Devolução'
	}

	get componenteDeCliente() {
		return !this.isVestcasa && this.venda && (!this.utilizarApenasCpf || this.venda.tipoDeTransacao === 'Devolução')
			? 'SeletorDePessoa'
			: 'CampoDeCpfOuCnpjOculto'
	}

	get infosCliente() {
		return VendaModule.informacoesDoClienteDaNota
	}

	set infosCliente(informacoes: typeof VendaModule['informacoesDoClienteDaNota']) {
		VendaModule.setInformacoesDoClienteDaNota(informacoes)
	}

	get clienteHint() {
		if (!this.infosCliente) return ''
		return this.infosCliente.mensagem
	}

	get obterIconeDoCampo() {
		return this.venda && this.utilizandoCampoDeCpf
			? this.venda.cliente ? 'mdi-pencil' : 'mdi-plus'
			: undefined
	}

	get carregandoOsItensDaVenda() {
		return VendaModule.carregandoItensDaVenda
	}

	@Watch('cpfOuCnpjDoCliente', { immediate: true })
	onChangeCliente(cpfOuCnpj: string | null, cpfOuCnpjAnterior: string | null) {
		if (cpfOuCnpjAnterior === undefined) return
		if (removerFormatacaoDeCnpjOuCpf(cpfOuCnpj) === removerFormatacaoDeCnpjOuCpf(cpfOuCnpjAnterior)) return
		this.aplicarClienteNaVenda(cpfOuCnpj)
	}

	async aplicarClienteNaVenda(cliente: Cliente | string | null, forcarReconsulta = false) {
		try {
			await AlterarPrecosDaNotaPorCliente({
				cliente,
				onAtualizarCarregando: (buscando) => this.buscandoCliente = buscando,
				forcarReconsulta,
				validarLimiteDeProduto: true,
			})
			
			const dataFormatada = new Date().toISOString().split('T')[0]
			const dataDia = dataFormatada.split('-')[2]
			const dataMes = dataFormatada.split('-')[1]
			const aniversario = this.venda?.cliente?.dataNascimento ? this.venda?.cliente?.dataNascimento.split('-') : []

			if(aniversario[2] == dataDia && aniversario[1] == dataMes){
				this.dialogoDeAniversarioDoCliente.mostrar()
			}

			const cpfOuCnpjFormatado: string = typeof cliente !== 'string'
				? cliente?.cnpjOuCpf || ''
				: cliente

			const venda = VendaModule.emissaoEntradaAtual

			if (venda) {
				const cpfOuCnpj = removerFormatacaoDeCnpjOuCpf(cpfOuCnpjFormatado)
				if (!forcarReconsulta && cpfOuCnpj === venda.cpfDoCliente && VendaModule.informacoesDoClienteDaNota) return

				venda.cpfDoCliente = cpfOuCnpj

				VendaModule.setEmissaoEntradaAtual(venda)
			}

		} catch (error: any) {
			AlertModule.setError(error)
			if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual') {
				const permissaoConcedida = await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })
				if (!permissaoConcedida) return 
				await AlterarPrecosDaVendaPorCliente({
					cliente,
					onAtualizarCarregando: (buscando) => this.buscandoCliente = buscando,
					forcarReconsulta,
					validarLimiteDeProduto: false,
				})
			}
		} finally {
			this.buscandoCliente = false
		}
	}

	@Watch('buscandoCliente')
	onChangeBuscandoCliente() {
		this.$emit('update:buscandoCliente', this.buscandoCliente)
	}
	
	@Watch("utilizarApenasCpf")
	onChangeUtilizarApenasCpf() {
		this.mostraTrocarUtilizarCpf = false
		if (!this.venda) return
		this.venda.cpfDoCliente = null
		this.venda.cliente = null
	}

	@Watch('mostraTrocarUtilizarCpf')
	onChangeMostraTrocarUtilizarCpf(mostra: boolean) {
		if (!mostra) {
			this.$nextTick(() => {
				this.focus()
			})
			return
		}
		setTimeout(() => {
			this.trocadorDeUtilizarApenasCpf.$el.focus()
		}, 125)
	}
}

