



















































import { ItemDaVenda } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'

@Component
export default class DialogoDeInformarNotaDeOrigem extends Vue {
	@Ref() form!: HTMLFormElement

	mostra: boolean | null = false
	item: ItemDaVenda | null = null
	chaveInformada: string | null = null
	notaRules = [
		(v: any) => !!v || 'Campo obrigatório',
		(v: any) => v !== null && v.length === 44 || 'Informe uma chave válida',
	]
	loading = false

	editarUmaVendaUseCase = new EditarUmaVendaUseCase()

	mostrar(item: ItemDaVenda) {
		this.mostra = true
		this.item = item
	}

	fechar(){
		this.item = null
		this.mostra = false
	}

	async informarNotaESerie() {
		if (!this.item) return
		if (!this.chaveInformada || this.chaveInformada.length !== 44) {
			AlertModule.setError('Informe uma chave válida')		
			return
		}

		try {
			this.item.chaveNotaOrigem = this.chaveInformada

			this.$emit('incluirItemDeVenda', this.item)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
			this.mostra = false
			this.chaveInformada = null
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}

}
