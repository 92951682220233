









































import { ItemDaVenda } from '@/models'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DialogoDeVincularChaveComItem extends Vue {
itensDaVenda: ItemDaVenda[] = []
mostrar = false

mostra(itens: ItemDaVenda[], mostra: boolean) {
	this.itensDaVenda = itens
	this.mostrar = mostra
}

confirmar() {
	this.mostrar = false
}

get itensSemChave() {
	return this.itensDaVenda.filter(i => i.chaveNotaOrigem === null) || []
}

}
