

































































































































































































































































































































































import { Vue as VueDecor, Component, Ref, Watch } from 'vue-property-decorator'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import CardDeProduto from '@/components/produto/CardDeProduto.vue'
import DialogoDeRetirarProduto from './DialogoDeRetirarProduto.vue'
import DialogoDeSelecionarVariante from '@/components/produto/DialogoDeSelecionarVariante.vue'
import {
	Venda,
	ItemDaVenda,
	ProdutoFilho,
	Desconto,
	FormProdutoSimples,
	ProdutoComVariantes,
	FormProdutoSimplesFilho,
	Categoria,
	FiltroDeBuscaDeProdutos,
	Loja,
} from '@/models'
import CardDeEntradas from './CardDeEntradas.vue'
import DialogoDeResumoDaEntrada from '@/components/venda/DialogoDeResumoDaEntrada.vue'
import DialogoDeFechamentoDeCaixa from '@/components/venda/DialogoDeFechamentoDeCaixa.vue'
import DialogoDeDevolucaoDeVenda from '@/components/venda/DialogoDeDevolucaoDeVenda.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import {
	EmitirNotaUseCase,
	FecharCaixaUseCase,
	FindCategoriaUseCase,
	FindConfiguracaoDaContaUseCase,
	FindLojaUseCase,
	FindProdutoUseCase,		
} from '@/usecases'
import type { PagamentoServiceAdapter, NotaFiscalServiceAdapter } from '@/usecases'
import type { VendaServiceAdapter } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeMovimentacoes from '@/components/venda/DialogoDeMovimentacoes.vue'
import DialogoDeReimpressao from '@/components/venda/DialogoDeReimpressao.vue'
import DialogoDeAutenticacao from './DialogoDeAutenticacao.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import AplicarPrecoDeCustoUseCase from '@/usecases/tabela-de-precos/AplicarPrecoDeCustoUseCase'
import { converterProdutoParaProdutoSimples, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import DialogoDeEdicaoDePagamento from '@/components/venda/DialogoDeEdicaoDePagamento.vue'
import DialogoDeConsultaDeProduto from './DialogoDeConsultaDeProduto.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeDescontoEmGrupo from './DialogoDeDescontoEmGrupo.vue'
import DialogoDeInformarNotaDeOrigem from './DialogoDeInformarNotaDeOrigem.vue'
import DialogoDeReabrirVenda from './DialogoDeReabrirVenda.vue'
import axios, { CancelTokenSource } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Inject } from 'inversify-props'
import Axios from 'axios'
import AlterarQuantidadeLimiteDoProduto from '@/components/ui/AlterarQuantidadeLimiteDoProduto.vue'
import Vue from "vue"
import VueQuagga from "vue-quaggajs"
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import DialogoDeDevolucaoDeVendaSemTurno from '@/components/venda/DialogoDeDevolucaoDeVendaSemTurno.vue'
import DialogoDeImportarPreVenda from './DialogoDeImportarPreVenda.vue'
import DialogoDeVincularChaveComItem from './DialogoDeVincularChaveComItem.vue'

Vue.use(VueQuagga);

const ULTIMA_VENDA_COM_ERRO = 'ULTIMA_VENDA_COM_ERRO'

@Component({
	components: {
		Confirmacao,
		CampoDeItemDeVenda,
		CardDeProduto,
		DialogoDeSelecionarVariante,
		CardDeEntradas,
		DialogoDeConsultaDeProduto,
		DialogoDeFechamentoDeCaixa,
		DialogoDeMovimentacoes,
		DialogoDeDevolucaoDeVenda,
		DialogoDeEdicaoDePagamento,
		DialogoDeReimpressao,
		DialogoDeDescontoEmGrupo,
		DialogoDeInformarNotaDeOrigem,
		DialogoDeReabrirVenda,
		DialogoDeRetirarProduto,
		DialogoDeAutenticacao,
		AlterarQuantidadeLimiteDoProduto,
		SeletorDeLojas,
		DialogoDeResumoDaEntrada,
		DialogoDeDevolucaoDeVendaSemTurno,
		DialogoDeImportarPreVenda,
		DialogoDeVincularChaveComItem,
	},
})
export default class TelaDeEmissaoDeNotas extends VueDecor {
	@Ref() readonly selecaoDeVariante!: DialogoDeSelecionarVariante
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	@Ref() readonly botaoDeVerPagamentos!: { $el: HTMLButtonElement }
	@Ref() readonly cardDeEntradas!: CardDeEntradas
	@Ref() campoDeConsultaDePreco!: DialogoDeConsultaDeProduto
	@Ref() dialogoDeDesconto!: DialogoDeDescontoEmGrupo
	@Ref() dialogoDeInformarNotaDeOrigem!: DialogoDeInformarNotaDeOrigem
	@Ref() dialogoDeReabrirVenda!: DialogoDeReabrirVenda
	@Ref() dialogoDeRetirarProduto!: DialogoDeRetirarProduto
	@Ref() dialogoDeAutenticacao!: DialogoDeAutenticacao
	@Ref() alterarQuantidadeLimiteDoProduto!: AlterarQuantidadeLimiteDoProduto
	@Ref() dialogoDeImportarPreVenda!: DialogoDeImportarPreVenda
	@Ref() dialogoDeVincularChaveComItem!: DialogoDeVincularChaveComItem

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	@Inject('NotaFiscalServiceAdapter')
	private notaFiscalService!: NotaFiscalServiceAdapter

	version = process.env.PACKAGE_VERSION || ''
	findProdutoUseCase = new FindProdutoUseCase()
	findCategoriaUseCase = new FindCategoriaUseCase()
	fecharCaixaUseCase = new FecharCaixaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	zIndex = 2
	requestsPendentes = 0
	pedindoCliente = false
	produtos: FormProdutoSimples[] = []
	buscandoCliente = false
	carregandoProdutos = true
	mostrarPagamento = false
	busca: FiltroDeBuscaDeProdutos = {
		tipos: ['Padrão', 'Com Variantes', 'Composto'],
	}
	categorias: Categoria[] = []
	cancelToken: CancelTokenSource | null = null
	parametroIdentificador: string | null = null

	mostrarDialogoSelecaoProduto = false
	mostrarDialogoLeituraDeCodigoBarras = false
	selecionandoProduto = false
	mostraDialogoDeImportarPreVenda = false
	permissaoConcedida: boolean | undefined = false
	tipoOperacao: string | null = null
	mostrarDialogoPrecoDeCusto = false
	utilizaPrecoDeCustoNaVenda = false

	readerSize = {
		width: 860,
		height: 480,
	}

	showCamera = false
	valorLido: string | null = null
	buscandoProduto = false

	mostrarDialogoParaInformarEmail = false

	mostraTelaDeDevolucao = false
	
	get devolucaoBuscada() {
		return this.$route.query.devolucaoBuscada
	}
	
	@Inject('PagamentoServiceAdapter')
	private pagamentoService!: PagamentoServiceAdapter

	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	set venda(venda: Venda | null) {
		VendaModule.setEmissaoEntradaAtual(venda)
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual
	}

	get loja() {
		return VendaModule.lojaDaEntrada
	}

	get vendaComErro() {
		if (!this.venda) return false

		const ultimaVendaComErro: Venda | null  = localStorage[ULTIMA_VENDA_COM_ERRO] 
			? JSON.parse(localStorage[ULTIMA_VENDA_COM_ERRO]) 
			: null

		return ultimaVendaComErro && ultimaVendaComErro.identificador === this.venda?.identificador
	}

	get lojaDaEntrada() {
		return VendaModule.lojaDaEntrada
	}

	set lojaDaEntrada(loja: Loja | null) {
		VendaModule.setLojaDaEntrada(loja)
	}

	get podeConsultarProduto() {
		return UserLoginStore.permiteRegraDeNegocio('dna-do-produto')
	}

	get valorDoDesconto(){
		if(!this.descontoEmSerie) return ''
		return this.descontoEmSerie.valor
	}

	get naoPodeIncluirProduto() {
		const podeIncluirProduto = this.venda && (
			this.venda.tipoDeTransacao === 'Devolução' &&
			(
				(this.venda.tipoNota === 'Saída'
				&& this.venda.cpfDoCliente != null
				&& this.venda.cpfDoCliente != '')
				|| 
				(this.venda.tipoNota === 'Entrada')
			)
			|| 
			this.venda.tipoDeTransacao === 'Transferência'
			|| 
			this.venda.tipoDeTransacao === 'Outros'
			|| 
			this.venda.tipoDeTransacao === 'Conserto'
			|| 
			this.venda.tipoDeTransacao === 'Ativo'
			|| 
			this.venda.tipoDeTransacao === 'Consumo'
			||
			this.venda.tipoDeTransacao === 'Venda'
			|| 
			this.venda.tipoDeTransacao === 'Uniforme'
		)

		return (!!this.venda && this.venda.tipoDeTransacao === 'Devolução' && this.$route.query.devolucaoBuscada === 'true') 
			|| this.buscandoCliente || !podeIncluirProduto
	}

	abrirDialogoDeImportarPreVenda() {
		this.mostraDialogoDeImportarPreVenda = true
	}

	@Watch('venda')
	abrirDialogoDeVincularChaveComItem() {
		if(this.itensSemVinculos.length > 0 && this.venda?.tipoDeTransacao === 'Devolução' && this.venda?.tipoNota === 'Saída') {
			this.dialogoDeVincularChaveComItem.mostra(this.itensSemVinculos, true)
		}
	}

	get itensSemVinculos() {
		return this.venda?.itens.filter(i => i.chaveNotaOrigem == null) || []
	}

	async abrirDialogoDeRemoverItens(){
		if (!await VendaModule.autenticarAcao({ regra: 'pode-excluir-item-de-venda' })) return
		this.dialogoDeRetirarProduto.mostrar()
	}

	async created() {
		this.lojaDaEntrada = null
		VendaModule.iniciarNovaNota({ identificador: '', lojaId: '' })
		this.$router
			.push({
				name: this.$route.name as string,
				query: {},
			})
	}

	async buscarCategorias() {		
		const page = await this.findCategoriaUseCase.findAll({ size: -1 }, {})
		this.categorias = page.content
	}

	async buscarProdutos() {
		this.carregandoProdutos = true

		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		try {
			const produtosPage = await this.findProdutoUseCase.find(this.busca, axiosConfig)
			this.produtos = produtosPage.content
			this.carregandoProdutos = false
		}
		catch(error) {
			if (Axios.isCancel(error))
				this.carregandoProdutos = false
		}
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeSelecaoDeProduto)
	}

	mounted() {
		this.focarBusca()
		VendaModule.setDialogDeAutenticacao(this.dialogoDeAutenticacao)
	}

	async validarParametroDevolucao() {
		if (this.$route.query.identificador !== undefined) {
			this.parametroIdentificador = this.$route.query.identificador.toString()
		} else {
			this.parametroIdentificador = ''
		}
	}

	excluirPagamentos(){
		const { venda } = this
		if(!venda) return
		this.venda = {
			...venda,
			pagamentos: [],
		}
	}

	atalhoDeSelecaoDeProduto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 's') {
			this.focarBusca()
		}
	}

	aplicarDescontoNaVenda(desconto: number) {
		this.descontoEmSerie = desconto
			? {
				isPercentual: true,
				valor: desconto,
			}
			: null
	}

	@Watch('venda', { immediate: true, deep: true })
	onChangeVenda(venda) {
		const novaVenda = venda
		VendaModule.setEmissaoEntradaAtual(novaVenda)
	}

	get descontoEmSerie() {
		return VendaModule.descontoEmSerie
	}

	set descontoEmSerie(desconto: Desconto | null) {
		VendaModule.setDescontoEmSerie(desconto)
	}

	async irParaResumo() {
		const valorTotal = this.venda?.itens.reduce((total, item) => {
			return total + item.preco * item.quantidade;
		}, 0).toFixed(2)

		const cliente = this.venda?.cliente?.cnpjOuCpf
		
		if (!cliente || !valorTotal || !this.venda) {
			AlertModule.setError("Defina um cliente antes de prosseguir.")
			return
		}
		
		const params = {
			tipoDeTransacao: this.venda.tipoDeTransacao,
			tipoNota: this.venda.tipoNota,
		}
		
		const notaJaEmitida = await this.notaFiscalService.findNotaDeMesmoValor(cliente, valorTotal, params)

		if (notaJaEmitida){
			AlertModule.setWarning("Já foi emitida uma nota de mesmo valor para este destinatário nos últimos 7 dias!")
		}

		this.mostraTelaDeResumo = true
	}

	iniciarNovaVenda() {
		this.requestsPendentes = 0
	}

	async finalizarVenda() {
		if (!this.venda) return

		try {
			
			await EmitirNotaUseCase({
				venda: this.venda,
			})

			this.iniciarNovaNota()
		} catch(error) {
			AlertModule.setError(error)
			throw error
		}

		this.mostraTelaDeResumo = false
		this.focarBusca()
		this.pedindoCliente = VendaModule.lembraDePedirOCliente
	}

	async validarTipoDeNota (item: ItemDaVenda) {
		if (this.venda?.tipoNota === 'Saída' && this.venda.tipoDeTransacao === 'Devolução'
				&& item.produto.sku && this.lojaDaEntrada) {

			try {
				this.buscandoCliente = true
				const itemNotaResumido = await this.notaFiscalService.findReferenciaDaEntrada(item.produto.sku, this.lojaDaEntrada?.id, this.venda.cpfDoCliente ? this.venda.cpfDoCliente : '')

				item.idItemNotaOrigem = itemNotaResumido.id
				item.chaveNotaOrigem = itemNotaResumido.chaveNotaOrigem

				this.incluirItemDeVenda(item)
			} catch (error: any) {
				this.dialogoDeInformarNotaDeOrigem.mostrar(item)
			} finally {
				this.buscandoCliente = false
				VendaModule.setCarregandoItensDaVenda(false)
			}
		} else {
			this.incluirItemDeVenda(item)
		}
	}

	get consultarPrecoDeCusto() {
		if (!this.venda) return false

		return this.venda.isBrinde ||
			this.venda.tipoDeTransacao === 'Transferência' ||
			(this.venda.tipoDeTransacao === 'Devolução' && this.venda.tipoNota === 'Saída') ||
			this.venda.tipoDeTransacao === 'Outros' || (this.venda.tipoDeTransacao === 'Venda' && this.utilizaPrecoDeCustoNaVenda)
	}

	async incluirItemDeVenda(item: ItemDaVenda) {
		if (!this.loja) return

		const tipoDeCliente = this.venda && this.venda.tipoDeCliente
			? this.venda.tipoDeCliente
			: null
		const itens = this.normalizaCasoSejaComposto(item).map(
			itemMap => ({
				...itemMap,
				desconto: this.descontoEmSerie || itemMap.desconto,
			}),
		)
		if (!this.venda) return
		try {
			++this.requestsPendentes
			let itensComPrecoAplicado

			if (this.consultarPrecoDeCusto) {
				itensComPrecoAplicado = await AplicarPrecoDeCustoUseCase({
					loja: this.loja,
					itens,
				})
			} else {
				itensComPrecoAplicado = await AplicarTabelaDePrecoUseCase({
					loja: this.loja,
					itens,
					tipoDeCliente,
					validarLimiteDeProduto: true,
					vendaAtual: this.venda,
				})
			}

			if (!this.venda) return

			itensComPrecoAplicado.forEach(itemComPrecoAplicado => {

				if (!this.venda) return

				if (this.venda?.tipoNota === 'Saída' && this.venda.tipoDeTransacao === 'Devolução') {
					this.venda = {
						...this.venda,
						itens: [
							...this.venda.itens,
							itemComPrecoAplicado,
						],
					}
					return
				}

				const indiceDoItemBipado = this.venda.itens.findIndex(
					( produto ) => produto.produto.id === item.produto.id && produto.idConjunto === null,
				)

				if (indiceDoItemBipado === -1) {
					this.venda.itens.unshift(itemComPrecoAplicado)
					return
				}
				const itemAnterior = this.venda.itens[indiceDoItemBipado]
				this.venda.itens.splice(indiceDoItemBipado, 1)

				const quantidadeTotalDoItem = itemAnterior.quantidade + item.quantidade

				this.venda.itens.unshift({
					...itemAnterior,
					quantidade: Number(quantidadeTotalDoItem.toFixed(3)),
				})
			})

		} catch (error: any) {
			AlertModule.setError(error)	
			if(AlertModule.getErrorMsg === 'Produto tem limite, favor informar CPF'){
				this.cardDeEntradas.pedirCliente()
			} else if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual') {
				this.podeAlterarQuantidadeDeProduto(item)
			}
		} finally {
			--this.requestsPendentes
			VendaModule.setCarregandoItensDaVenda(false)
		}
		this.focarBusca()
	}

	async podeAlterarQuantidadeDeProduto(item: ItemDaVenda) {
		if (!this.loja) return

		const tipoDeCliente =
			this.venda && this.venda.tipoDeCliente ? this.venda.tipoDeCliente : null

		const itens = this.normalizaCasoSejaComposto(item).map(itemMap => ({
			...itemMap,
			desconto: this.descontoEmSerie || itemMap.desconto,
		}))

		if (!this.venda) return

		try {

			const itensComPrecoAplicado = await AplicarTabelaDePrecoUseCase({
				loja: this.loja,
				itens,
				tipoDeCliente,
				validarLimiteDeProduto: false,
				vendaAtual: this.venda,
			})
			this.permissaoConcedida = await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })
			if (!this.permissaoConcedida || !this.venda) return 
			this.alterarQuantidadeLimiteDoProduto.mostrar(itensComPrecoAplicado[0])

		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	normalizaCasoSejaComposto(item: ItemDaVenda) {
		let itens: ItemDaVenda[] = []
		
		if (item.produto.tipo === 'Composto') {
			const idConjunto = uuidv4()

			itens = item.produto.itens.map(itemDoProduto => ({
				...item,
				produto: itemDoProduto.produto,
				quantidade: item.quantidade * itemDoProduto.quantidade,
				idConjunto: idConjunto,
				desconto: itemDoProduto.desconto,
			}))
		} else {
			itens = [item]
		}

		return itens
	}

	incluirProdutoNaVenda(produto: ProdutoFilho | FormProdutoSimplesFilho) {
		if (!this.venda) return
		try {
			const itemDeVenda: ItemDaVenda = {
				id: '',
				produto: converterProdutoParaProdutoSimples(produto),
				desconto: { isPercentual: true, valor: 0 },
				preco: produto.preco || 0,
				descontoVarejo: produto.descontoVarejo,
				quantidade: 1,
				precoDeCusto: 0,
				idConjunto: null,
				percentualCashback: 0,
				diasParaEfetivarCashback: 0,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: 0,
				possuiDescontoPorTabela: false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: false,
				isTotalmenteTrocado: false,
				motivoCancelamento: null,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: false,
				vendedor: null,
				autenticadoParaLiberarProdutoSemEstoque: false,
				vendaOrigemDoItem: null,
				valorFreteRateado: 0,
				isItemReferenteATroca: false,
			};
			this.validarTipoDeNota(itemDeVenda)
			this.focarBusca()
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async selecionarProduto(produtoSimplificado: FormProdutoSimples) {
		try {
			this.selecionandoProduto = true
			this.mostrarDialogoSelecaoProduto = false
			if (produtoSimplificado.tipo === 'Com Variantes') {
				const produto = this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
				this.selecaoDeVariante.abrir(produto as Promise<ProdutoComVariantes>)
				this.mostrarDialogoSelecaoProduto = false
				return
			}

			const produto = await this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
			if (produto.tipo !== 'Com Variantes')
				this.incluirProdutoNaVenda(produto)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.selecionandoProduto = false
		}
	}

	focarBusca() {
		if(this.$route.query.voltarPraPedido === 'true') return
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}

	get mostraTelaDeResumo() {
		return this.$route.query.payment !== undefined ? true : false
	}

	set mostraTelaDeResumo(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ...this.$route.query, payment: mostra ? null : undefined },
			})
			.catch()
	}

	@Watch('mostraTelaDeResumo')
	onChangeMostraTelaDePagamento(mostra: boolean) {
		if (mostra) {
			PluginModule.emit('abrirPagamentos', { venda: this.venda })
			return
		}
		window.requestAnimationFrame(() => {
			this.botaoDeVerPagamentos.$el.focus()
		})
	}

	get mostraFechamento() {
		return this.$route.query.closing !== undefined
	}

	set mostraFechamento(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { closing: mostra ? null : undefined },
			})
			.catch()
	}

	get mostraMovimentacao() {
		return this.$route.query['cash-handling'] !== undefined ? true : false
	}

	set mostraMovimentacao(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ['cash-handling']: mostra ? null : undefined },
			})
			.catch()
	}

	get mostrarReimpressao() {
		return this.$route.query['reimpressao'] !== undefined ? true : false
	}

	set mostrarReimpressao(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ['comprovantes']: mostra ? null : undefined },
			})
			.catch()
	}

	get temRequisicoesPendentes() {
		return !!this.requestsPendentes
	}

	async beforeRouteEnter(to, from, next){
		if ( to.query.closing !== undefined && await VendaModule.moverParaMovimentacoesCasoNaoTenhaAnexo()) return
		next()
	}

	async abrirConsultaDePreco(){
		if (!await VendaModule.autenticarAcao({ regra: 'dna-do-produto' })) return
		this.campoDeConsultaDePreco.mostrar()
	}

	@Watch('busca', { deep: true })
	async onChangeBusca() {
		this.buscarProdutos()
	}

	async selecionarCategoria(categoria: Categoria, active: boolean) {
		this.busca.categorias = active ? [ categoria.id ] : undefined
		this.buscarProdutos()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true

		this.valorLido = data.codeResult.code

		if (this.valorLido && this.lojaDaEntrada) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.lojaDaEntrada.id,
				)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)
				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco||0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
				}

				this.validarTipoDeNota(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.mostrarDialogoLeituraDeCodigoBarras = false
			}
		}''
	}

	@Watch('$route.query.novaEntrada')
	onChangeNovaEntrada() {
		if (!this.$route.query.novaEntrada) return

		if (this.$route.query.novaEntrada === 'true') {
			VendaModule.iniciarNovaNota({identificador: '', lojaId: ''})

			this.$router
				.push({
					name: this.$route.name as string,
					query: {
						devolucaoBuscada: undefined,
					},
				})
		}

	}

	@Watch('loja')
	async onChangeLojaDaNota() {
		let identificador = ''
		let lojaId = ''
		if (this.lojaDaEntrada != null) {
			identificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(this.lojaDaEntrada.id || '')
			lojaId = this.lojaDaEntrada.id
		}

		VendaModule.iniciarNovaNota({ identificador, lojaId, tipoDeTransacao: this.venda?.tipoDeTransacao })
	}

	@Watch('venda.tipoNota')
	onChangeTipoNota() {
		if (this.lojaDaEntrada !== undefined &&
			(
				this.venda?.tipoDeTransacao === 'Devolução'
				|| this.venda?.tipoDeTransacao === 'Conserto'
			)
		&& this.venda.tipoNota === 'Entrada') {
			this.mostraTelaDeDevolucao = true
		} else {
			this.mostraTelaDeDevolucao = false
		}
	}

	@Watch('$route.query.devolucaoBuscada')
	onChangeDevolucaoBuscada() {
		if (this.$route.query.devolucaoBuscada === 'true') {
			this.mostraTelaDeDevolucao = false
		}
	}

	iniciarNovaNota() {
		VendaModule.iniciarNovaNota({identificador: '', lojaId: ''})
		this.$router
			.push({
				name: this.$route.name as string,
				query: {
					devolucaoBuscada: undefined,
				},
			})
	}

	@Watch('venda.tipoDeTransacao')
	onChangeTipoDeTransacao(){
		if(this.venda?.tipoDeTransacao === 'Venda'){
			if (this.venda.itens && this.venda.itens.length > 0) {
				this.venda.itens = [];
			}
			this.mostrarDialogoPrecoDeCusto = true
		}
	}

	confirmarPrecoDeCusto() {
		this.utilizaPrecoDeCustoNaVenda = true
		this.mostrarDialogoPrecoDeCusto = false
	}

	fecharDialogoPrecoDeCusto() {
		this.utilizaPrecoDeCustoNaVenda = false
		this.mostrarDialogoPrecoDeCusto = false
	}
}

