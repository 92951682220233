

















































































































































































































































































































































































































import {
	Vue,
	Component,
	PropSync,
	Watch,
	Ref,
	Prop,
} from 'vue-property-decorator'
import {
	Venda,
	FormPagamento,
	CupomDeDesconto,
	Loja,
	DetalhesDoPagamento,
} from '@/models'
import { formatarMoeda, fixarCasasDecimais } from '@/shareds/formatadores'
import {
	obterItensComTaxaAplicada,
	obterRestanteDaVenda,
	obterSomaTotalDaTaxaPorItem,
	obterTotalDaVenda,
	obterTotalDeTaxas,
	obterTotalDosItensDaVenda,
	obterTrocoDaVenda,
	obterValorAPagar,
} from '@/shareds/venda-shareds'
import ResumoDaNota from './ResumoDaNota.vue'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { TipoDePagamento } from '@/models/venda/TipoDePagamento'
import MatrizDeTiposDePagamento from './MatrizDeTiposDePagamento.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeEdicaoDePagamento from '@/components/venda/DialogoDeEdicaoDePagamento.vue'
import DialogoDeEdicaoDeFrete from '@/components/venda/DialogoDeEdicaoDeFrete.vue'
import { criarFormPagamento } from '@/shareds/shared-pagamento'
import DialogoDeCupons from '@/components/venda/DialogoDeCupons.vue'
import { AxiosError } from 'axios'
import { FindLojaUseCase } from '@/usecases'
import { SerieFiscal } from '@/models/SerieFiscal'
import DialogoDeEdicaoInformacoesAdicionais from './DialogoDeEdicaoInformacoesAdicionais.vue'

@Component({
	components: {
		ResumoDaNota,
		CampoDecimal,
		MatrizDeTiposDePagamento,
		Confirmacao,
		DialogoDeEdicaoDePagamento,
		DialogoDeCupons,
		DialogoDeEdicaoDeFrete,
		DialogoDeEdicaoInformacoesAdicionais,
	},
})
export default class DialogoDeResumoDaEntrada extends Vue {
	@Ref() botaoDePagamentos?: { $el: HTMLButtonElement }
	@Ref() botaoDeFrete?: { $el: HTMLButtonElement }
	@Ref() campoDeValorAPagar!: CampoDecimal
	@Ref() cardText!: HTMLLinkElement
	@Ref() botaoDeEncerrarVenda!: { $el: HTMLButtonElement }
	@Ref() seletorDeParcelas!: HTMLInputElement & { isMenuActive: boolean }
	@Ref() confirmacaoDeCpfNaNota!: Confirmacao
	@Ref() confirmacaoDeValorDeVoucher!: Confirmacao
	@Ref() confirmacaoCrediario!: Confirmacao
	@PropSync('mostra', { type: Boolean, default: true }) syncMostra!: boolean
	@PropSync('vendaComErro', { type: Boolean, default: false }) syncVendaComErro!: boolean
	@Ref() botaoConfirmarParcelas!: { $el: HTMLButtonElement }
	@Prop({ type: Function }) onGerarRecibo?: (venda: Venda) => Promise<void>
	@Prop({ type: Function }) onSolicitarNsuOuCodigo?: (tipoDePagamento: TipoDePagamento) => Promise<void>
	@Ref() dialogoDeCupons!: DialogoDeCupons
	@Ref() botaoParaAplicarCupom!: { $el: HTMLButtonElement }

	formatarMoeda = formatarMoeda
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	obterTotalDeTaxas = obterTotalDeTaxas
	obterItensComTaxaAplicada = obterItensComTaxaAplicada

	findLojaUseCase = new FindLojaUseCase()

	paginaAtualDeTipos = 0
	tipoSelecionado: TipoDePagamento | null = null
	detalhesInformados: DetalhesDoPagamento | null = null

	pagamento: FormPagamento = criarFormPagamento()

	detalhes:  DetalhesDoPagamento | null = null
	mostraSelecaoDeParcelas = false
	mostraCampoNsu = false
	enviandoVenda = false
	encerrandoVenda = false
	gerandoRecibo = false
	errosAoGerarRecibo: string[] = []
	buscaDeTipo = ''
	mostraAguardandoPagamento = false
	criandoDetalhesDoPagamento = false
	formaDePagamentoSelecionado = ''
	contadorTef = 0
	situacaoPagamento = ''
	retornoAdquirente: string | null = null
	aguardandoPagamento = false
	tentativasEfetuarTef = 0
	podeLiberarCrediarioParaCliente = false
	nsu: string | null = null
	codigoAutorizacao: string | null = null
	series: SerieFiscal[] = []
	buscandoSeries = false
	serieSelecionada: SerieFiscal | null = null

	get parcelasComTaxasFormatado() {
	
		return  this.pagamento.tipoDePagamento != null && this.pagamento.tipoDePagamento.parcelasComTaxas != null
			? this.pagamento.tipoDePagamento.parcelasComTaxas.map(e => e.parcelamentos)
				.sort((pA, pB) =>{
					if( pA < pB) return -1
					if( pA > pB) return 1
					return 0
				}) : []
	}

	set venda(venda: Venda) {
		VendaModule.setEmissaoEntradaAtual(venda)
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual as Venda
	}

	get loja() {
		return VendaModule.lojaDaEntrada as Loja
	}

	get valorAPagar() {
		return typeof this.pagamento.valor === 'number'
			? Math.abs(this.pagamento.valor)
			: this.restante
	}

	set valorAPagar(valor: number | null | '') {
		if (typeof valor !== 'number') {
			this.pagamento.valor = valor
		} else {
			this.pagamento.valor =
				this.venda.tipoDeTransacao === 'Devolução' ? -valor : valor
		}
	}

	get desabilitarCartoes() {
		const aPagar = Number(this.valorAPagar)
		const aPagarFormatado = Math.round(aPagar * 100) / 100
		const restante = Number(this.restante)
		const restanteFormatado = Math.round(restante * 100) / 100

		return (
			!this.loja.configuracaoDaLoja.trocoParaCartao &&
			restanteFormatado < aPagarFormatado
		)
	}

	get tiposDePagamento(): TipoDePagamento[] {
		return []
	}

	created() {
		PluginModule.on('adicionarPagamento', this.adicionarPagamento)
		document.addEventListener('keydown', this.atalhoDeValor)
		document.addEventListener('keydown', this.atalhoDePagamentos)
		document.addEventListener('keydown', this.atalhoDeAplicarCupons)
	}

	destroyed() {
		PluginModule.removeListener('adicionarPagamento')
		document.removeEventListener('keydown', this.atalhoDeValor)
		document.removeEventListener('keydown', this.atalhoDePagamentos)
		document.removeEventListener('keydown', this.atalhoDeAplicarCupons)
	}

	atalhoDeValor(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (event.altKey && event.key.toLowerCase() === 'v') {
			event.preventDefault()
			this.campoDeValorAPagar.focus()
		}
	}

	atalhoDePagamentos(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (!event.altKey || event.key.toLowerCase() !== 'p') return
		if (!this.botaoDePagamentos) return

		event.preventDefault()
		event.stopImmediatePropagation()
		this.botaoDePagamentos.$el.click()
	}

	atalhoDeAplicarCupons(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (!event.altKey || event.key.toLowerCase() !== 'c') return
		if (!this.botaoParaAplicarCupom) return

		event.preventDefault()
		event.stopImmediatePropagation()
		this.botaoParaAplicarCupom.$el.click()
	}

	get restante() {
		return obterRestanteDaVenda(this.venda)
	}

	get troco() {
		return obterTrocoDaVenda(this.venda)
	}

	async buscarSeriesFiscaisDisponiveis() {
		try {
			this.buscandoSeries = true
			this.series = await this.findLojaUseCase.buscarSeriesDeDanfes(this.loja.id)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscandoSeries = false
		}
	}

	selecionarSerie(serie: SerieFiscal) {
		this.serieSelecionada = serie

		this.venda.serie = serie
	}

	async selecionarTipoDePagamento(tipoDePagamento: TipoDePagamento) {
		this.venda.liberarCrediario = false
		const crediarioEMenor = this.crediarioDisponivelNoCliente < Number(this.valorAPagar)
	
		if(crediarioEMenor && tipoDePagamento.formaDePagamento == 'Crediário') {
			const confirmacaoParaLiberarLimite = await this.confirmacaoCrediario.mostrar()

			if(confirmacaoParaLiberarLimite) {
				const autenticacaoValida = await this.podeLiberarCrediario()
				if (!autenticacaoValida) return

				this.venda.liberarCrediario = autenticacaoValida
				this.pagamento.valor = this.valorAPagar
				this.podeLiberarCrediarioParaCliente = true	 
			}
		} 
		if (!this.campoDeValorAPagar.validate()) return

		const pluginAssumeSelecao = await PluginModule.deixarPluginAssumirControle(
			'selecionarTipoDePagamento',
			{
				valorAPagar: this.valorAPagar,
				venda: this.venda,
				lojaDaVenda: VendaModule.lojaDaEntrada,
				tipoDePagamento,
			},
		)
		if (pluginAssumeSelecao) return

		this.pagamento.tipoDePagamento = tipoDePagamento
		this.tipoSelecionado = tipoDePagamento

		if (this.tipoSelecionado.parcelasComTaxas != null && this.tipoSelecionado.parcelasComTaxas.length > 0) {
			this.mostraSelecaoDeParcelas = true
			setTimeout(() => {
				this.seletorDeParcelas.focus()
				this.seletorDeParcelas.isMenuActive = true
			}, 170)
		} else (
			this.adicionarNsu(this.pagamento.tipoDePagamento)
		)
		
	}

	adicionarNsu(tipoDePagamento: TipoDePagamento | null) {
		if(!tipoDePagamento) return
		
		this.mostraSelecaoDeParcelas = false

		if(tipoDePagamento.exigeNsu == true || tipoDePagamento.exigeCodAutorizacao == true) {
			this.mostraCampoNsu = true
		} else {
			this.adicionarPagamento({
				...this.pagamento,
			})
		}
	}

	get podeEmitirNfce() {
		return (
			this.total < 10000 &&
			(!this.venda.cliente ||
				this.venda.cliente?.inscricaoEstadual === 'ISENTO')
		)
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	obterValorPagamento(pagamento: FormPagamento) {

		let valorDoPagamento = pagamento.valor || this.restante

		if (
			typeof this.creditoDisponivelNoCliente === 'number' &&
			pagamento.tipoDePagamento?.formaDePagamento === 'Voucher'
		) {
			if (this.creditoDisponivelNoCliente < valorDoPagamento)
				valorDoPagamento = this.creditoDisponivelNoCliente
		}

		if (
			typeof this.creditoDisponivelNoCliente === 'number' &&
			pagamento.tipoDePagamento?.formaDePagamento === 'Crediário'
		) {
			if (this.crediarioDisponivelNoCliente < valorDoPagamento && !this.podeLiberarCrediarioParaCliente) {
				valorDoPagamento = this.crediarioDisponivelNoCliente
			} else { 
				this.venda.cliente 
					? this.venda.cliente.disponivelEmCredito = this.crediarioDisponivelNoCliente - Number(pagamento.valor)
					: null
				valorDoPagamento = Number(this.valorAPagar)
			}
			
			this.podeLiberarCrediarioParaCliente = false
		} 

		return valorDoPagamento
	}

	async podeLiberarCrediario() {
		return await VendaModule.autenticarAcaoTelaDeCaixa({ 
			regra:'permite-vendas-acima-do-valor-limite-do-funcionario', 
		})
	}

	adicionarPagamento(pagamento: FormPagamento) {
		if (!pagamento.tipoDePagamento) return
		this.criandoDetalhesDoPagamento = true

		this.confirmarPagamento(pagamento)
	}

	async confirmarPagamento(pagamento: FormPagamento) {
		if (!pagamento.tipoDePagamento) return
		if (pagamento.valor !== null && !pagamento.valor) return
			
		const valor = this.obterValorPagamento(pagamento)

		let valorDaTaxa = 0
		try {
			if (pagamento.tipoDePagamento.parcelasComTaxas && pagamento.qtdeDeParcelas) {
				valorDaTaxa = obterSomaTotalDaTaxaPorItem(
					valor,
					pagamento.tipoDePagamento.parcelasComTaxas,
					pagamento.qtdeDeParcelas,
				)
			}
				
			this.detalhesInformados = {
				id: '',
				identificadorExterno: '' ,
				status: '',
				nsu: this.nsu ? this.nsu : '',
				numeroAutorizacao: this.codigoAutorizacao ? this.codigoAutorizacao : '',
				adquirente: '',
				bandeira: '',
				msgAdquirente: '',
				comprovanteAdquirente: '',
				identificadorDaVenda: this.venda.identificador ? this.venda.identificador : '',
				statusDoPagamento: null,
			}

			this.mostraCampoNsu = false
			this.criandoDetalhesDoPagamento = false
			this.nsu = null
			this.codigoAutorizacao = null
			this.venda = {
				...this.venda,
				pagamentos: [
					...this.venda.pagamentos,
					{
						id: '',
						valor,
						tipoDePagamento: pagamento.tipoDePagamento,
						dataHoraPagamento: new Date(),
						qtdeDeParcelas: pagamento.tipoDePagamento.parcelasComTaxas != null
							? (pagamento.qtdeDeParcelas as number)
							: 0,
						status: 'Pago',
						valorDaTaxa: valorDaTaxa,
						detalhesDoPagamentoId: !this.detalhes ? null : this.detalhes.id,
						link: pagamento.link,
						valePresenteId: pagamento.valePresenteId,
					},
				],
			}
			this.pagamento = criarFormPagamento()
			setTimeout(() => {
				this.cardText.scrollTop = this.cardText.scrollHeight
				this.campoDeValorAPagar && this.campoDeValorAPagar.focus()
			})
		}catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async cancelarPorIndice(indice) {
		try {
			this.venda.pagamentos.splice(indice, 1)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	validarStatusPagamentoTefFinalizado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
			case '18':
			case '19':
				return false
			default:
				return true
		}
	}

	validarStatusPagamentoTefAprovado(detalhesDoPagamento: DetalhesDoPagamento) {
		switch (detalhesDoPagamento.status) {
			case '10':
			case '20':
				return true
			default:
				return false
		}
	}

	validarStatusCancelamentoTefAprovado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '20':
				return true
			default:
				return false
		}
	}

	validarStatusPagamentoTefDescricao(detalhesDoPagamento: DetalhesDoPagamento) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
				this.aguardandoPagamento = true
				return 'Aguardando pagamento, insira ou aproxime o cartão'
			case '10':
				return 'Pagamento finalizado'
			case '15':
			case '25':
				return 'Pagamento recusado'
			case '18':
			case '19':
				this.aguardandoPagamento = true
				return 'Aguardando cancelamento'
			case '20':
				return 'Pagamento cancelado'
			default:
				return 'Situação do pagamento indefinida'
		}
	}

	@Watch('syncMostra', { immediate: true })
	onChangeMostra(syncMostra: DialogoDeResumoDaEntrada['syncMostra']) {

		if (syncMostra) {
			this.buscarSeriesFiscaisDisponiveis()
			this.serieSelecionada = null
		}

		if (!syncMostra || this.pagamentoConcluido) return

		this.pagamento = criarFormPagamento()

		setTimeout(() => {
			this.campoDeValorAPagar.focus()
		})
		setTimeout(() => {
			this.questionarUsoDeVoucherDisponivel()
		})
	}

	async questionarUsoDeVoucherDisponivel() {
		if (!this.syncMostra) return
		if (!this.creditoDisponivelNoCliente) return

		const tipoDePagamentoComVoucher = this.tiposDePagamento.find(
			({ formaDePagamento }) => formaDePagamento === 'Voucher',
		)
		if (!tipoDePagamentoComVoucher) return

		const usarVoucher = await this.confirmacaoDeValorDeVoucher.mostrar()
		if (!usarVoucher) return

		this.selecionarTipoDePagamento(tipoDePagamentoComVoucher)
	}

	get creditoDisponivelNoCliente() {
		if (this.venda.tipoDeTransacao === 'Devolução') return undefined
		return this.venda.cliente
			? this.venda.cliente.disponivelEmCredito -
					this.venda.pagamentos
						.filter(
							pagamento =>
								pagamento.tipoDePagamento.formaDePagamento === 'Voucher',
						)
						.reduce((total, pagamento) => total + pagamento.valor, 0)
			: 0
	}

	get crediarioDisponivelNoCliente() {
		const pagamentosEmCrediario = this.venda.pagamentos
			.filter(p => p.tipoDePagamento.formaDePagamento === 'Crediário')
			.map(p => p.valor)
			.reduce((total, valor) => total + valor, 0)

		return (
			(this.venda.cliente?.limiteDoCrediario || 0) -
			(this.venda.cliente?.valorPendenteEmCrediario || 0) -
			pagamentosEmCrediario
		)
	}

	voltar() {
		this.$emit('voltar')
	}

	async imprimirRecibo() {
		if (this.enviandoVenda) return
		if (!this.onGerarRecibo) return
		try {
			this.enviandoVenda = true
			this.gerandoRecibo = true
			this.errosAoGerarRecibo = []
			this.venda.exibirCpfNaNota = true

			await this.onGerarRecibo(this.venda)
			this.errosAoGerarRecibo = []
		} catch (error: any) {
			const errAxios: AxiosError = error as AxiosError
			if (
				errAxios.response &&
				errAxios.response.data &&
				Array.isArray(errAxios.response.data)
			) {
				this.errosAoGerarRecibo = errAxios.response.data
			} else {
				AlertModule.setError(error)
			}
		} finally {
			this.enviandoVenda = false
			this.gerandoRecibo = false
		}
	}

	get pagamentoConcluido() {
		return fixarCasasDecimais(obterValorAPagar(this.venda), 2) === 0
	}

	mostraTrocaDePagamento() {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { devolucao: null },
			})
			.catch()
	}

	get displayCodigoDoCupom() {
		return this.venda.cuponsDeDesconto && this.venda.cuponsDeDesconto.length
			? this.venda.cuponsDeDesconto[0].codigo
			: ''
	}

	abrirDialogoDeCupons() {
		this.dialogoDeCupons.mostrar()
	}

	aplicarCupomNaVenda(cupom: CupomDeDesconto) {
		this.venda = {
			...this.venda,
			cuponsDeDesconto: [...this.venda.cuponsDeDesconto, cupom],
		}
	}

	removerCuponsAplicados() {
		this.venda = {
			...this.venda,
			cuponsDeDesconto: [],
		}
	}
}
